export default {
  text: '#0c3450',
  primary: '#0c324e',
  secondary: '#ababab',
  brandGrey: '#566464',
  background: 'white',
  backgroundSecondary: '#202020',
  light: '#FFF',
  dark: '#020303'
}
